<template>
	<div class="cropper-content">
		<div class="cropper-box">
			<div class="cropper">
				<vue-cropper
					ref="cropper"
					:img="option.img"
					:outputSize="option.outputSize"
					:outputType="option.outputType"
					:info="option.info"
					:canScale="option.canScale"
					:autoCrop="option.autoCrop"
					:autoCropWidth="option.autoCropWidth"
					:autoCropHeight="option.autoCropHeight"
					:fixed="option.fixed"
					:fixedNumber="option.fixedNumber"
					:full="option.full"
					:fixedBox="option.fixedBox"
					:canMove="option.canMove"
					:canMoveBox="option.canMoveBox"
					:original="option.original"
					:centerBox="option.centerBox"
					:height="option.height"
					:infoTrue="option.infoTrue"
					:maxImgSize="option.maxImgSize"
					:enlarge="option.enlarge"
					:mode="option.mode"
					@realTime="realTime"
					@imgLoad="imgLoad">
				</vue-cropper>
			</div>
			<!--底部操作工具按钮-->
			<div class="footer-btn">
				<div class="scope-btn">
					<label class="btn" for="uploads">选择</label>
					<input
						type="file"
						id="uploads"
						style="position: absolute; clip: rect(0 0 0 0)"
						accept="image/png, image/jpeg, image/gif, image/jpg"
						@change="selectImg($event)" />
					<a-button size="small" type="primary" plain icon="arrows-alt" @click="changeScale(1)"> 放大 </a-button>
					<a-button size="small" type="primary" plain icon="shrink" @click="changeScale(-1)"> 缩小 </a-button>
					<a-button size="small" type="primary" plain @click="rotateLeft"> ↺ 左旋转 </a-button>
					<a-button size="small" type="primary" plain @click="rotateRight"> ↻ 右旋转 </a-button>
				</div>
				<div class="upload-btn">
					<a-button size="small" type="primary" @click="uploadImg()">确定图片<i class="cloud-upload"></i></a-button>
				</div>
			</div>
		</div>
		<!--预览效果图-->
		<div class="show-preview">
			<div :style="previews.div" class="preview">
				<img :src="previews.url" :style="previews.img" />
			</div>
		</div>
	</div>
</template>

<script>
	import { VueCropper } from "vue-cropper";
	import { apiList } from "@/api/api";
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
	export default {
		name: "CropperImage",
		components: {
			VueCropper,
		},
		props: ["Name", "cropperData"],
		data() {
			return {
				name: this.Name,
				previews: {},
				option: {
					img: this.cropperData, //裁剪图片的地址
					outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
					outputType: "png", //裁剪生成图片的格式（jpeg || png || webp）
					info: true, //图片大小信息
					canScale: true, //图片是否允许滚轮缩放
					autoCrop: true, //是否默认生成截图框
					autoCropWidth: 400, //默认生成截图框宽度
					autoCropHeight: 400, //默认生成截图框高度
					fixed: true, //是否开启截图框宽高固定比例
					fixedNumber: [1, 1], //截图框的宽高比例
					full: false, //false按原比例裁切图片，不失真
					fixedBox: true, //固定截图框大小，不允许改变
					canMove: true, //上传图片是否可以移动
					canMoveBox: true, //截图框能否拖动
					original: false, //上传图片按照原始比例渲染
					centerBox: false, //截图框是否被限制在图片里面
					height: true, //是否按照设备的dpr 输出等比例图片
					infoTrue: false, //true为展示真实输出图片宽高，false展示看到的截图框宽高
					maxImgSize: 400, //限制图片最大宽度和高度
					enlarge: 1, //图片根据截图框输出比例倍数
					mode: "400px 400px", //图片默认渲染方式
				},
				// 上传信息
				upload: {
					loading: false,
					action: apiList.common.upload.url,
					headers: {
						token: "",
					},
					accept: "image/png, image/jpeg",
					data: {
						is_user_image: 1,
					},
				},
				uploadData: {
					is_user_image: 1,
					file: "",
				},
				title: "",
			};
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"]),
		},
		created() {
			this.upload.headers.token = this.token;
		},
		methods: {
			//初始化函数
			imgLoad(msg) {
				console.log("工具初始化函数=====" + msg);
			},
			//图片缩放
			changeScale(num) {
				num = num || 1;
				this.$refs.cropper.changeScale(num);
			},
			//向左旋转
			rotateLeft() {
				this.$refs.cropper.rotateLeft();
			},
			//向右旋转
			rotateRight() {
				this.$refs.cropper.rotateRight();
			},
			//实时预览函数
			realTime(data) {
				this.previews = data;
			},
			//选择图片
			selectImg(e) {
				let file = e.target.files[0];
				if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
					this.$message({
						message: "图片类型要求：jpeg、jpg、png",
						type: "error",
					});
					return false;
				}
				//转化为blob
				let reader = new FileReader();
				reader.onload = (e) => {
					let data;
					if (typeof e.target.result === "object") {
						data = window.URL.createObjectURL(new Blob([e.target.result]));
					} else {
						data = e.target.result;
					}
					this.option.img = data;
				};
				//转化为base64
				reader.readAsDataURL(file);
			},
			sendItem(value) {
				this.option.img = value;
			},

			//上传图片
			uploadImg(type) {
				//获取截图的blob数据   getCropBlob   getCropData
				this.$refs.cropper.getCropData(async (data) => {
					console.log("569", data); //base64数据
					let aaa = this.dataURLtoFile(data, "截图.png");
					this.$emit("getCropData", aaa);
				});
			},

			//将base64转换为文件
			dataURLtoFile(dataurl, filename) {
				var arr = dataurl.split(","),
					mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]),
					n = bstr.length,
					u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new File([u8arr], filename, { type: mime });
			},

			//上传图片
			uploadImg1(type) {
				let _this = this;
				if (type === "blob") {
					//获取截图的blob数据
					this.$refs.cropper.getCropBlob(async (data) => {
						let formData = new FormData();
						formData.append("file", data, "DX.jpg");
						//调用axios上传
						let { data: res } = await _this.$http.post("/api/file/imgUpload", formData);
						if (res.code === 200) {
							_this.$message({
								message: res.msg,
								type: "success",
							});
							let data = res.data.replace("[", "").replace("]", "").split(",");
							let imgInfo = {
								name: _this.Name,
								url: data[0],
							};
							_this.$emit("uploadImgSuccess", imgInfo);
						} else {
							_this.$message({
								message: "文件服务异常，请联系管理员！",
								type: "error",
							});
						}
					});
				}
			},

			base64toBlob(base64, type) {
				base64.replace(/%0A|\s/g, ""); //去掉多余的空格 和%A
				// 将base64转为Unicode规则编码
				let bstr = atob(base64),
					n = bstr.length,
					u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n); // 转换编码后才可以使用charCodeAt 找到Unicode编码
				}
				return new Blob([u8arr], {
					type,
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.cropper-content {
		display: flex;
		display: -webkit-flex;
		justify-content: flex-end;

		.cropper-box {
			flex: 1;
			width: 100%;

			.cropper {
				width: auto;
				height: 450px;
			}
		}

		.show-preview {
			flex: 1;
			-webkit-flex: 1;
			display: flex;
			display: -webkit-flex;
			justify-content: center;

			.preview {
				overflow: hidden;
				border: 1px solid #67c23a;
				background: #cccccc;
			}
		}
	}

	.footer-btn {
		margin-top: 30px;
		display: flex;
		display: -webkit-flex;
		justify-content: flex-end;

		.scope-btn {
			display: flex;
			display: -webkit-flex;
			justify-content: space-between;
			padding-right: 10px;
			.ant-btn-primary {
				margin-right: 8px;
			}
		}

		.upload-btn {
			flex: 1;
			-webkit-flex: 1;
			// display: flex;
			display: -webkit-flex;
			// justify-content: center;
		}

		.btn {
			outline: none;
			display: inline-block;
			line-height: 18px;
			white-space: nowrap;
			cursor: pointer;
			-webkit-appearance: none;
			text-align: center;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			outline: 0;
			-webkit-transition: 0.1s;
			transition: 0.1s;
			font-weight: 500;
			padding: 8px 15px;
			font-size: 12px;
			border-radius: 3px;
			color: #fff;
			background-color: #409eff;
			border-color: #409eff;
			margin-right: 10px;
		}
	}
</style>
